import React from "react";
import PptxGenJS from "pptxgenjs";
import { FaDownload } from 'react-icons/fa';

const DownloadPPT = ({
  showScenarios,
  baseScenarioValues,
  scenario1Values,
  scenario2Values,
  scenario3Values,
  estimatedVolumeBase,
  estimatedValueBase,
  estimatedVolumeScenario1,
  estimatedValueScenario1,
  estimatedVolumeScenario2,
  estimatedValueScenario2,
  estimatedVolumeScenario3,
  estimatedValueScenario3,
  appliedFilters,
}) => {
  const generatePPT = () => {
    const ppt = new PptxGenJS();

    // Slide 1: Title Slide
    const slide1 = ppt.addSlide();
    slide1.background = { color: "e7f3ff" }; // Light blue background
    slide1.addText("Scenario Analysis Overview", {
      x: 1,
      y: 1,
      fontSize: 28,
      color: "0032a0", // Dark blue
      bold: true,
      fontFace: "Arial",
    });
    slide1.addText("Data Insights and Projections", {
      x: 1,
      y: 1.8,
      fontSize: 20,
      color: "0032a0",
      italic: true,
    });

    // Scenario Data
    const scenarios = [
      {
        title: "Base Scenario",
        values: baseScenarioValues,
        estimatedVolume: estimatedVolumeBase,
        estimatedValue: estimatedValueBase,
      },
      {
        title: "Scenario 1",
        values: scenario1Values,
        estimatedVolume: estimatedVolumeScenario1,
        estimatedValue: estimatedValueScenario1,
      },
      {
        title: "Scenario 2",
        values: scenario2Values,
        estimatedVolume: estimatedVolumeScenario2,
        estimatedValue: estimatedValueScenario2,
      },
      {
        title: "Scenario 3",
        values: scenario3Values,
        estimatedVolume: estimatedVolumeScenario3,
        estimatedValue: estimatedValueScenario3,
      },
    ];

    // Generate slides dynamically for scenarios
    scenarios.slice(0, showScenarios + 1).forEach((scenario) => {
      const slide = ppt.addSlide();

      // Add Scenario Title
      slide.addText(scenario.title, {
        x: 0.5,
        y: 0.3, // Positioning above filters
        fontSize: 20,
        color: "0032a0",
        bold: true,
      });
      // Filter out empty or undefined filters
      const filterData = Object.entries(appliedFilters)
        .filter(([_, value]) => value && value.trim() !== "" && value !== "N/A") // Keep only filters with non-empty values
        .map(([key, value]) => [
          { text: key, options: { bold: true } },
          value,
        ]);

      // Add Filters Table if there are valid filters
      // Add Filters Heading and Table if there are valid filters
      if (filterData.length > 0) {
        slide.addText("Applied Filters", {
          x: 0.5,
          y: 1,
          fontSize: 16,
          color: "0032a0",
          bold: true,
        });
        slide.addTable(filterData, {
          x: 0.5,
          y: 1.2,
          w: 8,
          border: { pt: 1, color: "0032a0" },
          fontSize: 14,
          color: "000000",
          fill: "e7f3ff",
        });
      }
      // Add Scenario Data in Table Form
      const scenarioData = [
        [
          { text: "Metric", options: { bold: true } },
          { text: "Value", options: { bold: true } },
        ],
        ...[
          { label: "Awareness %", name: "awareness" },
          { label: "Purchase Intention %", name: "purchaseIntention" },
          { label: "Distribution %", name: "distribution" },
          { label: "Frequency", name: "frequency" },
          { label: "NSP €", name: "nsp" },
          { label: "Estimated Volume", name: "estimatedVolume" },
          { label: "Estimated Value", name: "estimatedValue" },
        ].map((field) => [
          field.label,
          scenario.values[field.name] ||
          scenario[field.name]?.toFixed(2) ||
          "N/A",
        ]),
      ];
      slide.addTable(scenarioData, {
        x: 0.5,
        y: filterData.length > 0 ? 3 : 1, // Adjust position based on whether filters are shown
        w: 8,
        border: { pt: 1, color: "0032a0" },
        fontSize: 14,
        color: "000000",
        fill: "f8f8f8",
      });
    });

    // Save the PPT file
    ppt.writeFile({ fileName: "Scenario_Analysis_Presentation.pptx" });
  };

  return (
    <button
      onClick={generatePPT}
      style={{
        backgroundColor: "#0032a0",
        color: "white",
        borderRadius: "5px",
        fontSize: "small",
        cursor: "pointer",
        padding: "2%",
      }}
    >
      <FaDownload className="refresh-icon" />
      Download PPT
    </button>
  );
};

export default DownloadPPT;
