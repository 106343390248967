import React, { useState, useEffect } from 'react';
import './App.css'; // Import app.css file
import Select from 'react-select'; // Import react-select
import Slider from '@mui/material/Slider'; // Import Slider component from Material-UI
import ScenarioForm from './ScenarioForm ';
import favicon from './img/favicon.png';
import DownloadPPT from '././DownloadButton';
import ImageSlider from "./ImageSlider";
import image1 from "../src/img/image1.jpg";
import image2 from "../src/img/image2.jpg";
import image3 from "../src/img/image3.jpg";
import image4 from "../src/img/image4.jpg";
import image5 from "../src/img/image5.jpg";
import { FaFilter, FaFileAlt, FaUsers, FaMapMarkerAlt, FaUserCircle, FaCogs, FaCalendarAlt, FaSyncAlt, FaGlobe, FaBoxOpen, FaListAlt, FaCoins } from 'react-icons/fa';

const images = [
  { src: image1, alt: "Image 1" },
  { src: image2, alt: "Image 2" },
  { src: image3, alt: "Image 3" },
  { src: image4, alt: "Image 4" },
  { src: image5, alt: "Image 5" },
  { src: image2, alt: "Image 2" },
  { src: image3, alt: "Image 3" },
  { src: image4, alt: "Image 4" },
  { src: image5, alt: "Image 5" },
  { src: image2, alt: "Image 2" },
  { src: image3, alt: "Image 3" },
  { src: image4, alt: "Image 4" },
  { src: image5, alt: "Image 5" },
];

function Calculator() {
  const [input, setInput] = useState('');
  const [countryFilter, setCountryFilter] = useState('');
  const [countries, setCountries] = useState([]);
  const [skinProblem, setSkinProblem] = useState('');
  const [genderFilter, setGenderFilter] = useState('');
  const [selectedCatFilter, setselectedCatFilter] = useState('');
  const [ageRanges, setAgeRanges] = useState([]);
  const [genders, setGenders] = useState([]);
  const [selectedCat, setSelectedCat] = useState([]);
  const [selectedPopulationSize, setSelectedPopulationSize] = useState(0);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedGenderFactor, setSelectedGenderFactor] = useState(1); // Default factor
  const [totalPopulationSize, setTotalPopulationSize] = useState(0); // Total population size
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedAgeRange, setSelectedAgeRange] = useState([]);
  const [estimatedVolumeScenario1, setEstimatedVolumeScenario1] = useState('');
  const [estimatedVolumeScenario2, setEstimatedVolumeScenario2] = useState('');
  const [estimatedVolumeScenario3, setEstimatedVolumeScenario3] = useState('');
  const [estimatedValueBase, setEstimatedValueBase] = useState(0);
  const [estimatedVolumeBase, setEstimatedVolumeBase] = useState(0);
  const [estimatedValueScenario1, setEstimatedValueScenario1] = useState(0);
  const [estimatedValueScenario2, setEstimatedValueScenario2] = useState(0);
  const [estimatedValueScenario3, setEstimatedValueScenario3] = useState(0);
  const [sec, setSec] = useState([]);
  const [secFactor, setSecFactor] = useState('');
  const [secValue, setSecValue] = useState('');
  const [selectedCatFactor, setSelectedCatFactor] = useState('');
  const [selectedCatValue, setSelectedCatValue] = useState('');
  const [selectedSubCatValue, setSelectedSubCatValue] = useState('');
  const [selectedSubCatFactor, setSetSelectedSubCatFactor] = useState('');
  const [selectedSubCat, setSelectedSubCat] = useState([]);
  const [scale1, setScale1] = useState([]);
  const [scale1Factor, setScale1Factor] = useState([]);
  const [scale1Value, setScale1Value] = useState([]);
  const [scale2, setScale2] = useState([]);
  const [scale2Factor, setScale2Factor] = useState([]);
  const [scale2Value, setScale2Value] = useState([]);
  const [scale3, setScale3] = useState([]);
  const [scale3Factor, setScale3Factor] = useState([]);
  const [scale3Value, setScale3Value] = useState([]);
  const [scale4, setScale4] = useState([]);
  const [scale4Factor, setScale4Factor] = useState([]);
  const [scale4Value, setScale4Value] = useState([]);
  const [scale5, setScale5] = useState([]);
  const [scale5Factor, setScale5Factor] = useState([]);
  const [scale5Value, setScale5Value] = useState([]);
  const [scale6, setScale6] = useState([]);
  const [scale6Factor, setScale6Factor] = useState([]);
  const [scale6Value, setScale6Value] = useState([]);
  const [scale7, setScale7] = useState([]);
  const [scale7Factor, setScale7Factor] = useState([]);
  const [scale7Value, setScale7Value] = useState([]);
  const [scale8, setScale8] = useState([]);
  const [scale8Factor, setScale8Factor] = useState([]);
  const [scale8Value, setScale8Value] = useState([]);
  const [scale1Selected, setScale1Selected] = useState([]);
  const [scale2Selected, setScale2Selected] = useState([]);
  const [scale3Selected, setScale3Selected] = useState([]);
  const [scale4Selected, setScale4Selected] = useState([]);
  const [scale5Selected, setScale5Selected] = useState([]);
  const [scale6Selected, setScale6Selected] = useState([]);
  const [scale7Selected, setScale7Selected] = useState([]);
  const [scale8Selected, setScale8Selected] = useState([]);
  const [selectedMin, setSelectedMin] = useState(0);
  const [selectedMax, setSelectedMax] = useState(0);
  const [calculatedFilters, setCalculatedFilters] = useState([]);
  // State variables to keep track of selected filters and their factors
  const [selectedFilters, setSelectedFilters] = useState({});
  const [appliedFactors, setAppliedFactors] = useState({});
  // State variable to keep track of the adjusted population size
  const [adjustedPopulationSize, setAdjustedPopulationSize] = useState(totalPopulationSize);
  const [sliderValue, setSliderValue] = useState(0); // State variable to control the slider value
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [yearValue, setYearValue] = useState('');
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [additionalVolumeInfo, setAdditionalVolumeInfo] = useState('');
  const [additionalValueInfo, setAdditionalValueInfo] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [showScenarios, setShowScenarios] = useState(0);
  const [selectedYear, setSelectedYear] = useState(''); // To store the selected year
  const [disabledYears, setDisabledYears] = useState([]); // To store the years that should be disabled
  const [launchName, setLaunchName] = useState(''); // To store the launch name
  const [productPicture, setProductPicture] = useState(null); // To store the uploaded product picture


  const handleRemoveScenario = (indexToRemove) => {
    if (showScenarios > 0) {
      setShowScenarios(showScenarios - 1);
      // Adjust the values of the remaining scenarios
      //else if(indexToRemove === 0)
      //   setBaseScenarioValues({...scenario1Values});
      if (indexToRemove === 1) {
        setScenario1Values({ ...scenario2Values });
        setScenario2Values({ ...scenario3Values });
      } else if (indexToRemove === 2) {
        setScenario2Values({ ...scenario3Values });
      }
    }
  };


  const handleAddScenario = () => {
    if (showScenarios < 3) {//added this condition for only 3 scenarion can be add
      setShowScenarios(showScenarios + 1);
      // Set the values for the new scenario based on the previous scenario
      if (showScenarios === 0)
        setScenario1Values({ ...baseScenarioValues });
      else if (showScenarios === 1) {
        setScenario2Values({ ...scenario1Values });
      } else if (showScenarios === 2) {
        setScenario3Values({ ...scenario2Values });
      }
    }
  };



  const handleAdditionalVolumeInfoChange = (event) => {
    setAdditionalVolumeInfo(event.target.value);
    // setAdditionalVolumeInfo('');
  };

  const handleAdditionalValueInfoChange = (event) => {
    setAdditionalValueInfo(event.target.value);
    // setAdditionalValueInfo('');
  };

  // Handler for Launch Name change
  const handleLaunchNameChange = (event) => {
    setLaunchName(event.target.value); // Update the launch name state
  };

  // Handler for Product Picture change (image upload)
  const handleProductPictureChange = (event) => {
    const file = event.target.files[0]; // Get the uploaded file

    if (file) {
      const validFormats = ["image/jpeg", "image/png", "application/pdf"];
      const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes

      if (!validFormats.includes(file.type)) {
        alert("Invalid file format. Please upload a JPG, PNG, or PDF file.");
        return;
      }

      if (file.size > maxFileSize) {
        alert("File size exceeds 1MB. Please upload a smaller file.");
        return;
      }

      setProductPicture(file); // Store the file (you can upload it later as needed)
    }
  };


  const [selectedRange, setSelectedRange] = useState([]);
  const [baseScenarioValues, setBaseScenarioValues] = useState({
    penetration: '100',
    incidence: '100',
    purchaseIntention: '100',
    awareness: '100',
    distribution: '100',
    frequency: '1',
    nsp: '1',
    // Add more input fields as needed
  });

  const [estimatedVolume, setEstimatedVolume] = useState('');
  const [scenario1Values, setScenario1Values] = useState({
    penetration: '100',
    incidence: '100',
    purchaseIntention: '100',
    awareness: '100',
    distribution: '100',
    frequency: '1',
    nsp: '1',
    // Add more input fields as needed
  });

  const [scenario2Values, setScenario2Values] = useState({
    penetration: '100',
    incidence: '100',
    purchaseIntention: '100',
    awareness: '100',
    distribution: '100',
    frequency: '1',
    nsp: '1',
    // Add more input fields as needed
  });


  const [scenario3Values, setScenario3Values] = useState({
    penetration: '100',
    incidence: '100',
    purchaseIntention: '100',
    awareness: '100',
    distribution: '100',
    frequency: '1',
    nsp: '1',
    // Add more input fields as needed
  });


  useEffect(() => {
    calculateEstimatedVolumeAndValue(baseScenarioValues, setEstimatedVolumeBase, setEstimatedValueBase);
  }, [baseScenarioValues, selectedPopulationSize]);

  useEffect(() => {
    calculateEstimatedVolumeAndValue(scenario1Values, setEstimatedVolumeScenario1, setEstimatedValueScenario1);
  }, [scenario1Values, selectedPopulationSize]);

  useEffect(() => {
    calculateEstimatedVolumeAndValue(scenario2Values, setEstimatedVolumeScenario2, setEstimatedValueScenario2);
  }, [scenario2Values, selectedPopulationSize]);

  useEffect(() => {
    calculateEstimatedVolumeAndValue(scenario3Values, setEstimatedVolumeScenario3, setEstimatedValueScenario3);
  }, [scenario3Values, selectedPopulationSize]);

  const calculateEstimatedVolumeAndValue = (values, setVolume, setValue) => {
    const { awareness, purchaseIntention, distribution, frequency, nsp } = values;

    const volumeFactor = (parseFloat(purchaseIntention) / 100) * (parseFloat(awareness) / 100) * (parseFloat(distribution) / 100);
    const estimatedVolume = selectedPopulationSize * volumeFactor * parseFloat(frequency);
    setVolume(estimatedVolume);

    const estimatedValue = nsp == 1 ? estimatedVolume : estimatedVolume * parseFloat(nsp);
    setValue(estimatedValue);
  };




  const handleScenario1Values = (event) => {
    const { name, value } = event.target;
    let parsedValue = parseFloat(value);

    // Ensure the entered value is not greater than 100
    if (parsedValue > 100) {
      parsedValue = 100;
    }

    // Handle the state update
    setScenario1Values(prevState => ({
      ...prevState,
      [name]: parsedValue
    }));
  };

  const handleScenario2Values = (event) => {
    const { name, value } = event.target;
    let parsedValue = parseFloat(value);

    // Ensure the entered value is not greater than 100
    if (parsedValue > 100) {
      parsedValue = 100;
    }

    // Handle the state update
    setScenario2Values(prevState => ({
      ...prevState,
      [name]: parsedValue
    }));
  };

  const handleScenario3Values = (event) => {
    const { name, value } = event.target;
    let parsedValue = parseFloat(value);

    // Ensure the entered value is not greater than 100
    if (parsedValue > 100) {
      parsedValue = 100;
    }

    // Handle the state update
    setScenario3Values(prevState => ({
      ...prevState,
      [name]: parsedValue
    }));
  };


  useEffect(() => {
    // Fetch the data from the text file
    fetch('/data/FinalData.txt') // Assuming the file is stored in 'public/data/data.txt'
      .then(response => response.text())
      .then(text => {
        // Split the text by new line characters
        const lines = text.trim().split('\n');

        // Parse each line as JSON
        const data = lines.map(line => JSON.parse(line));

        // Filter data based on type
        // const countryData = data.filter(item => item.type === 'country');
        const countryData = data.filter(item => item.type === 'country').map(country => ({
          ...country,
          populationSize: parseInt(country.populationSize) // Convert populationSize to integer
        }));
        // setCountries(countryData);
        const genderData = data.filter(item => item.type === 'gender');
        const selectedCat = data.filter(item => item.type === 'selectedcat')
        const selectedSubCatData = data.filter(item => item.type === 'selectedsubcat')
        const ageRanges = data.filter(item => item.type === 'age');
        const secData = data.filter(item => item.type === 'sec');
        const Scale1 = data.filter(item => item.type === 'scale1');
        const Scale2 = data.filter(item => item.type === 'scale2');
        const Scale3 = data.filter(item => item.type === 'scale3');
        const Scale4 = data.filter(item => item.type === 'scale4');
        const Scale5 = data.filter(item => item.type === 'scale5');
        const Scale6 = data.filter(item => item.type === 'scale6');
        const Scale7 = data.filter(item => item.type === 'scale7');
        const Scale8 = data.filter(item => item.type === 'scale8');
        const options = data.filter(item => item.type === 'additional').map(option => option.value);// Extract year options for NPD
        const yearData = data.filter(item => item.type === 'NPD');
        const selectedYear = yearData.filter(item => item.selected == '1').map(item => item.value); // Get the preselected value based on 'selected' property
        const yearOptions = [...new Set(yearData.map(item => item.value))];
        const disabledYears = yearData.filter(item => item.disabled == '1').map(item => item.value); // Disable years that are not selected


        // Update state with filtered data
        setCountries(countryData);
        setGenders(genderData);
        setSelectedCat(selectedCat);
        setSelectedSubCat(selectedSubCatData);
        setSec(secData);
        setAgeRanges(ageRanges);
        setScale1(Scale1);
        setScale2(Scale2);
        setScale3(Scale3);
        setScale4(Scale4);
        setScale5(Scale5);
        setScale6(Scale6);
        setScale7(Scale7);
        setScale8(Scale8);
        setYearOptions(yearOptions);
        setSelectedYear(selectedYear); // Preselect Year based on 'selected' property
        setDisabledYears(disabledYears); // Set disabled years dynamically
        setFilterOptions(options);

        // Calculate total population size initially
        const totalSize = countryData.reduce((total, country) => total + country.populationSize, 0);
        setTotalPopulationSize(totalSize);
        setSelectedPopulationSize(totalSize * selectedGenderFactor); // Adjusted based on gender factor
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (selectedYear) {
      setYearValue(selectedYear);
    }
  }, [selectedYear]);

  const handleAdditionalFilterChange = (event) => {
    setSelectedFilter(event.target.value);
    // Reset additional info when filter changes
    // setYearValue('');
  };

  const handleAdditionalInfoChange = (event) => {
    setYearValue(event.target.value);
  };

  useEffect(() => {
    // If no countries are selected, set the population sum to the total population size
    const populationSum = selectedCountries.length > 0
      ? selectedCountries.reduce((total, country) => total + country.value.populationSize, 0)
      : totalPopulationSize; // If no countries are selected, set to totalPopulationSize

    // Apply the selected gender factor
    setSelectedPopulationSize(populationSum);
  }, [selectedCountries, totalPopulationSize]);


  const handleCountryChange = (selectedOptions) => {
    setSelectedCountries(selectedOptions);
  };

  const handleGenderChange = (selectedOptions) => {
    // const selectedGender = genders.find(gender => gender.value === e.target.value);
    // Calculate the combined factor for selected genders
    const combinedFactor = selectedOptions.reduce((total, selectedOption) => {
      const selectedGender = genders.find(gender => gender.value === selectedOption.value);
      if (selectedGender) {
        return total + (parseFloat(selectedGender.factor) || 0);
      }
      return total;
    }, 0);
    setSelectedGenderFactor(combinedFactor); // Store the selected gender's factor
    handleFilterChange('gender', combinedFactor);
    // Update selected genders state
    setSelectedGenders(selectedOptions);
    // Recalculate population size based on selected countries and gender factor
    const countryPopulation = selectedCountries.reduce((total, country) => total + country.value.populationSize, 0);
    // setSelectedPopulationSize(populationSum * parseFloat(selectedGender.factor));
    if (combinedFactor) {
      setSelectedGenderFactor(parseFloat(combinedFactor));
      // Ensure that all factors used in the calculation are initialized and valid
      const catFactor = parseFloat(selectedCatFactor) || 1; // Use 1 as default if selectedCatFactor is not a valid number
      const subCatFactor = parseFloat(selectedSubCatFactor) || 1; // Use 1 as default if selectedSubCatFactor is not a valid number
      const ageRangeFactor = parseFloat(selectedAgeRange) || 1;
      const selectsec = parseFloat(secFactor) || 1;
      const selectscale1Factor = parseFloat(scale1Factor) || 1; // Use 1 as default if selectedScale1 is not a valid number
      const selectscale2Factor = parseFloat(scale2Factor) || 1; // Use 1 as default if selectedScale2 is not a valid number
      const selectscale3Factor = parseFloat(scale3Factor) || 1; // Use 1 as default if selectedScale3 is not a valid number
      const selectscale4Factor = parseFloat(scale4Factor) || 1; // Use 1 as default if selectedScale4 is not a valid number
      const selectscale5Factor = parseFloat(scale5Factor) || 1; // Use 1 as default if selectedScale5 is not a valid number
      const selectscale6Factor = parseFloat(scale6Factor) || 1; // Use 1 as default if selectedScale6 is not a valid number
      const selectscale7Factor = parseFloat(scale7Factor) || 1; // Use 1 as default if selectedScale7 is not a valid number
      const selectscale8Factor = parseFloat(scale8Factor) || 1; // Use 1 as default if selectedScale8 is not a valid number

      let adjustedPopulation;
      if (countryPopulation === 0) {
        adjustedPopulation = totalPopulationSize * combinedFactor * ageRangeFactor * catFactor
          * subCatFactor * selectsec * selectscale1Factor * selectscale2Factor * selectscale3Factor
          * selectscale4Factor * selectscale5Factor * selectscale6Factor * selectscale7Factor
          * selectscale8Factor;
      } else {
        adjustedPopulation = countryPopulation * combinedFactor * ageRangeFactor * catFactor
          * subCatFactor * selectsec * selectscale1Factor * selectscale2Factor * selectscale3Factor
          * selectscale4Factor * selectscale5Factor * selectscale6Factor * selectscale7Factor
          * selectscale8Factor;
      }

      // Update the selected population size state directly
      setSelectedPopulationSize(adjustedPopulation);
    }

  };

  const handleBaseScenarioValues = (event) => {
    const { name, value } = event.target;
    let parsedValue = parseFloat(value);


    // Ensure the entered value is not greater than 100
    if (parsedValue > 100) {
      parsedValue = 100;
    }

    // Handle the state update
    setBaseScenarioValues(prevState => ({
      ...prevState,
      [name]: parsedValue
    }));
  };
  const clearFilters = () => {
    // Reset all the filter states to their default values.
    setSelectedCountries([]);
    setSelectedGenders([]);
    setSelectedRange([]);
    setSelectedCatValue('');
    setSelectedSubCatValue('');
    setSecValue([]);
    setScale1Selected([]);
    setScale2Selected([]);
    setScale3Selected([]);
    setScale6Selected([]);
    // Add more reset actions as needed
  };


  // Function to calculate the adjusted population size based on selected filters
  const calculateAdjustedPopulationSize = () => {
    // Ensure that all factors used in the calculation are initialized and valid
    const catFactor = parseFloat(selectedCatFactor) || 1;
    const subCatFactor = parseFloat(selectedSubCatFactor) || 1;
    const genderFactor = parseFloat(selectedGenderFactor) || 1;
    const ageRangeFactor = parseFloat(selectedAgeRange) || 1;
    const selectsec = parseFloat(secFactor) || 1;
    const selectscale1Factor = parseFloat(scale1Factor) || 1;
    const selectscale2Factor = parseFloat(scale2Factor) || 1;
    const selectscale3Factor = parseFloat(scale3Factor) || 1;
    const selectscale4Factor = parseFloat(scale4Factor) || 1;
    const selectscale5Factor = parseFloat(scale5Factor) || 1;
    const selectscale6Factor = parseFloat(scale6Factor) || 1;
    const selectscale7Factor = parseFloat(scale7Factor) || 1;
    const selectscale8Factor = parseFloat(scale8Factor) || 1;

    // Calculate adjusted population size based on selected filters
    const populationSum = selectedCountries.reduce((total, country) => total + country.value.populationSize, 0);
    const adjustedPopulation = populationSum * catFactor * subCatFactor * genderFactor * ageRangeFactor * selectsec * selectscale1Factor * selectscale2Factor * selectscale3Factor * selectscale4Factor * selectscale5Factor * selectscale6Factor * selectscale7Factor * selectscale8Factor;

    // Update the state with the calculated adjusted population size
    setAdjustedPopulationSize(adjustedPopulation);
  };
  // Function to handle filter change
  const handleFilterChange = (filter, factor) => {
    // If the filter is already selected, subtract its previous factor from the population size
    if (selectedFilters.hasOwnProperty(filter)) {
      const previousFactor = selectedFilters[filter];
      const adjustedPopulationSize = selectedPopulationSize / previousFactor;
      setSelectedPopulationSize(adjustedPopulationSize);
    }
    // Add or update the filter with its factor
    selectedFilters[filter] = parseFloat(factor);

    // Recalculate adjusted population size
    calculateAdjustedPopulationSize();
  };
  // Call the function whenever there's a change in selected filters
  useEffect(() => {
    calculateAdjustedPopulationSize();
  }, [selectedFilters]);

  // useEffect for initial calculation
  useEffect(() => {
    calculateAdjustedPopulationSize();
  }, []);


  // Call the function whenever there's a change in relevant factors
  useEffect(() => {
    calculateAdjustedPopulationSize();
  }, [selectedGenderFactor, secFactor, selectedCatFactor, selectedSubCatFactor,
    selectedAgeRange, scale1, scale2, scale3, scale4, scale5, scale6, scale7, scale8]);

  // useEffect for initial calculation
  useEffect(() => {
    calculateAdjustedPopulationSize();
  }, []);

  // const handleAgeSliderChange = (event, newValue) => {
  //   let minValue, maxValue;

  //   if (Array.isArray(newValue)) {
  //     [minValue, maxValue] = newValue;
  //   } else {
  //     minValue = newValue;
  //     maxValue = newValue;
  //   }

  //   setSelectedMin(minValue);
  //   setSelectedMax(maxValue);
  //   setSelectedRange([minValue, maxValue]);

  //   let totalFactor = 0;
  //   let ageCount = 0;

  //   ageRanges.forEach(ageRange => {
  //     if (ageRange.value.max >= minValue && ageRange.value.min <= maxValue) {
  //       const start = Math.max(minValue, ageRange.value.min);
  //       const end = Math.min(maxValue, ageRange.value.max);
  //       const rangeCount = end - start + 1;
  //       // totalFactor += parseFloat(ageRange.factor) * rangeCount;///parseFloat(selectedGender.factor) || 0
  //       totalFactor += parseFloat(ageRange.factor);
  //       ageCount += rangeCount;
  //       console.log(`Range: ${ageRange.label}, Factor: ${ageRange.factor}, Count: ${rangeCount}`);
  //     }
  //   });

  //   let sumOfFactors = totalFactor; // Sum of factors for the selected range
  //   let averageFactor = 0;
  //   if (ageCount > 0) {
  //     averageFactor = totalFactor; // Sum of factors for the selected range
  //     averageFactor = parseFloat(averageFactor.toFixed(4));
  //     setSelectedAgeRange(averageFactor);
  //   }

  //   console.log('Total Factor:', totalFactor);
  //   console.log('Age Count:', ageCount);
  //   console.log('Average Factor:', averageFactor);

  //   const ageRangeFactor = parseFloat(averageFactor.toFixed(4)) || 1;
  //   const catFactor = parseFloat(selectedCatFactor) || 1;
  //   const subCatFactor = parseFloat(selectedSubCatFactor) || 1;
  //   const genderFactor = parseFloat(selectedGenderFactor) || 1;
  //   const selectsec = parseFloat(secFactor) || 1;
  //   const selectscale1Factor = parseFloat(scale1Factor) || 1;
  //   const selectscale2Factor = parseFloat(scale2Factor) || 1;
  //   const selectscale3Factor = parseFloat(scale3Factor) || 1;
  //   const selectscale4Factor = parseFloat(scale4Factor) || 1;
  //   const selectscale5Factor = parseFloat(scale5Factor) || 1;
  //   const selectscale6Factor = parseFloat(scale6Factor) || 1;
  //   const selectscale7Factor = parseFloat(scale7Factor) || 1;
  //   const selectscale8Factor = parseFloat(scale8Factor) || 1;

  //   const countryPopulation = selectedCountries.reduce((total, country) => total + country.value.populationSize, 0);
  //   let adjustedPopulation;
  //   if (countryPopulation === 0) {
  //     console.log('Total population:', totalPopulationSize);
  //     adjustedPopulation = totalPopulationSize * ageRangeFactor * catFactor * genderFactor
  //       * subCatFactor * selectsec * selectscale1Factor * selectscale2Factor * selectscale3Factor
  //       * selectscale4Factor * selectscale5Factor * selectscale6Factor * selectscale7Factor
  //       * selectscale8Factor;
  //   } else {
  //     adjustedPopulation = countryPopulation * ageRangeFactor * catFactor * genderFactor
  //       * subCatFactor * selectsec * selectscale1Factor * selectscale2Factor * selectscale3Factor
  //       * selectscale4Factor * selectscale5Factor * selectscale6Factor * selectscale7Factor
  //       * selectscale8Factor;
  //   }
  //   adjustedPopulation = parseFloat(adjustedPopulation.toFixed(4));
  //   console.log('adjustedPopulation:', adjustedPopulation);
  //   setSelectedPopulationSize(adjustedPopulation);
  // };

  const handleAgeSelectChange = (selectedOptions) => {
    setSelectedRange(selectedOptions);

    let totalFactor = 0;

    // Calculate total factor based on selected options
    selectedOptions.forEach(option => {
      const ageRange = ageRanges.find(range => range.label === option.label);
      if (ageRange) {
        totalFactor += parseFloat(ageRange.factor);
      }
    });

    const averageFactor = totalFactor ? parseFloat(totalFactor.toFixed(4)) : 0;
    setSelectedAgeRange(averageFactor);

    console.log('Total Factor:', totalFactor);
    console.log('Average Factor:', averageFactor);

    // Calculate adjusted population
    const ageRangeFactor = averageFactor || 1;
    const catFactor = parseFloat(selectedCatFactor) || 1;
    const subCatFactor = parseFloat(selectedSubCatFactor) || 1;
    const genderFactor = parseFloat(selectedGenderFactor) || 1;
    const selectsec = parseFloat(secFactor) || 1;
    const selectscale1Factor = parseFloat(scale1Factor) || 1;
    const selectscale2Factor = parseFloat(scale2Factor) || 1;
    const selectscale3Factor = parseFloat(scale3Factor) || 1;
    const selectscale4Factor = parseFloat(scale4Factor) || 1;
    const selectscale5Factor = parseFloat(scale5Factor) || 1;
    const selectscale6Factor = parseFloat(scale6Factor) || 1;
    const selectscale7Factor = parseFloat(scale7Factor) || 1;
    const selectscale8Factor = parseFloat(scale8Factor) || 1;

    const countryPopulation = selectedCountries.reduce((total, country) => total + country.value.populationSize, 0);
    let adjustedPopulation;

    if (countryPopulation === 0) {
      adjustedPopulation = totalPopulationSize * ageRangeFactor * catFactor * genderFactor
        * subCatFactor * selectsec * selectscale1Factor * selectscale2Factor * selectscale3Factor
        * selectscale4Factor * selectscale5Factor * selectscale6Factor * selectscale7Factor
        * selectscale8Factor;
    } else {
      adjustedPopulation = countryPopulation * ageRangeFactor * catFactor * genderFactor
        * subCatFactor * selectsec * selectscale1Factor * selectscale2Factor * selectscale3Factor
        * selectscale4Factor * selectscale5Factor * selectscale6Factor * selectscale7Factor
        * selectscale8Factor;
    }

    adjustedPopulation = parseFloat(adjustedPopulation.toFixed(4));
    console.log('Adjusted Population:', adjustedPopulation);
    setSelectedPopulationSize(adjustedPopulation);
  };

  const handleSecChange = (selectedOptions) => {
    // Extract values of selected options
    const selectedValues = selectedOptions.map(option => option.value);

    let factorValue = 0;

    // Iterate through selected values to calculate factorValue
    selectedValues.forEach(value => {
      // Handle combined options
      if (value === "A/B") {
        const factorA = parseFloat(sec.find(item => item.text === "A")?.factor) || 0;
        const factorB = parseFloat(sec.find(item => item.text === "B")?.factor) || 0;
        factorValue += factorA + factorB;
      } else if (value === "C1/C2") {
        const factorC1 = parseFloat(sec.find(item => item.text === "C1")?.factor) || 0;
        const factorC2 = parseFloat(sec.find(item => item.text === "C2")?.factor) || 0;
        factorValue += factorC1 + factorC2;
      } else if (value === "D/E") {
        const factorD = parseFloat(sec.find(item => item.text === "D")?.factor) || 0;
        const factorE = parseFloat(sec.find(item => item.text === "E")?.factor) || 0;
        factorValue += factorD + factorE;
      } else {
        // Handle individual options
        const selectedSec = sec.find(item => item.text === value);
        factorValue += parseFloat(selectedSec?.factor) || 0;
      }
    });

    setSecValue(selectedOptions.label);
    setSecFactor(factorValue);

    // Recalculate population size based on selected countries and the new factor
    const selectsec = parseFloat(factorValue) || 1; // Use 1 as default if factorValue is not a valid number
    const genderFactor = parseFloat(selectedGenderFactor) || 1;
    const ageRangeFactor = parseFloat(selectedAgeRange) || 1;
    const catFactor = parseFloat(selectedCatFactor) || 1; // Use 1 as default if selectedCatFactor is not a valid number
    const selectscale1Factor = parseFloat(scale1Factor) || 1; // Use 1 as default if selectedScale1 is not a valid number
    const selectscale2Factor = parseFloat(scale2Factor) || 1; // Use 1 as default if selectedScale2 is not a valid number
    const selectscale3Factor = parseFloat(scale3Factor) || 1; // Use 1 as default if selectedScale3 is not a valid number
    const selectscale4Factor = parseFloat(scale4Factor) || 1; // Use 1 as default if selectedScale4 is not a valid number
    const selectscale5Factor = parseFloat(scale5Factor) || 1; // Use 1 as default if selectedScale5 is not a valid number
    const selectscale6Factor = parseFloat(scale6Factor) || 1; // Use 1 as default if selectedScale6 is not a valid number
    const selectscale7Factor = parseFloat(scale7Factor) || 1; // Use 1 as default if selectedScale7 is not a valid number
    const selectscale8Factor = parseFloat(scale8Factor) || 1; // Use 1 as default if selectedScale8 is not a valid number

    const populationSum = selectedCountries.reduce((total, country) => total + country.value.populationSize, 0);
    let adjustedPopulation;
    if (populationSum === 0) {
      adjustedPopulation = totalPopulationSize * selectsec * genderFactor * ageRangeFactor * catFactor * selectscale1Factor * selectscale2Factor * selectscale3Factor * selectscale4Factor * selectscale5Factor * selectscale6Factor * selectscale7Factor * selectscale8Factor;
    } else {
      adjustedPopulation = populationSum * selectsec * genderFactor * ageRangeFactor * catFactor * selectscale1Factor * selectscale2Factor * selectscale3Factor * selectscale4Factor * selectscale5Factor * selectscale6Factor * selectscale7Factor * selectscale8Factor;
    }

    setSelectedPopulationSize(adjustedPopulation);
  };

  const handleSlectedCatChange = (e) => {
    const value = e.target.value;
    const selected = selectedCat.find(item => item.text === value);

    let factorValue = 1; // Default value if no category is selected
    let filteredSubCat = [];
    if (selected) {
      factorValue = parseFloat(selected.factor) || 1; // Extract factor value or default to 1

      // Filter subcategories based on the selected category
      filteredSubCat = selectedSubCat.filter(subCat => {
        if (selected.text === 'Face Care' || selected.text === 'Face Cleansing') {
          return ['Even Tone', 'Acne', 'Anti-Age', 'Moisturizers', 'Others'].includes(subCat.text);
        } else {
          return subCat.text === 'default'; // Default logic for other categories
        }
      });

      setSelectedCatValue(selected.text);
      handleFilterChange('category', factorValue);
    } else {
      setSelectedCatValue(''); // Reset category value
      handleFilterChange('category', factorValue); // Reset the factor to 1 in filter change
    }

    setSelectedCatFactor(factorValue); // Update factor value
    setSubCategories(filteredSubCat);
    setSetSelectedSubCatFactor(''); // Reset sub-category factor
    setSelectedSubCatValue(''); // Reset sub-category value

    let populationSum = selectedCountries.reduce((total, country) => total + country.value.populationSize, 0);

    // Calculate adjusted population
    let genderFactor = parseFloat(selectedGenderFactor) || 1;
    let ageRangeFactor = parseFloat(selectedAgeRange) || 1;
    let selectsec = parseFloat(secFactor) || 1;
    let selectscale1Factor = parseFloat(scale1Factor) || 1;
    let selectscale2Factor = parseFloat(scale2Factor) || 1;
    let selectscale3Factor = parseFloat(scale3Factor) || 1;
    let selectscale4Factor = parseFloat(scale4Factor) || 1;
    let selectscale5Factor = parseFloat(scale5Factor) || 1;
    let selectscale6Factor = parseFloat(scale6Factor) || 1;
    let selectscale7Factor = parseFloat(scale7Factor) || 1;
    let selectscale8Factor = parseFloat(scale8Factor) || 1;

    let adjustedPopulation;
    if (populationSum === 0) {
      adjustedPopulation = totalPopulationSize * factorValue * genderFactor * ageRangeFactor * selectsec *
        selectscale1Factor * selectscale2Factor * selectscale3Factor * selectscale4Factor *
        selectscale5Factor * selectscale6Factor * selectscale7Factor * selectscale8Factor;
    } else {
      adjustedPopulation = populationSum * factorValue * genderFactor * ageRangeFactor * selectsec *
        selectscale1Factor * selectscale2Factor * selectscale3Factor * selectscale4Factor *
        selectscale5Factor * selectscale6Factor * selectscale7Factor * selectscale8Factor;
    }

    setSelectedPopulationSize(adjustedPopulation);
  };

  const handleSlectedSubCatChange = (e) => {
    const value = e.target.value;
    const selected = selectedSubCat.find(item => item.text === value);

    let factorValue = 1; // Default factor value if no subcategory is selected
    if (selected) {
      factorValue = parseFloat(selected.factor) || 1; // Extract factor value or default to 1
      setSetSelectedSubCatFactor(factorValue);
      setSelectedSubCatValue(selected.text);
      handleFilterChange('subcategory', factorValue);
    } else {
      setSetSelectedSubCatFactor(''); // Reset factor if no valid subcategory is selected
      setSelectedSubCatValue(''); // Reset subcategory value
      handleFilterChange('subcategory', 1); // Reset subcategory factor in filter change
    }

    // Ensure all factors used in the calculation are initialized and valid
    const catFactor = parseFloat(selectedCatFactor) || 1;
    const genderFactor = parseFloat(selectedGenderFactor) || 1;
    const ageRangeFactor = parseFloat(selectedAgeRange) || 1;
    const selectsec = parseFloat(secFactor) || 1;
    const selectscale1Factor = parseFloat(scale1Factor) || 1;
    const selectscale2Factor = parseFloat(scale2Factor) || 1;
    const selectscale3Factor = parseFloat(scale3Factor) || 1;
    const selectscale4Factor = parseFloat(scale4Factor) || 1;
    const selectscale5Factor = parseFloat(scale5Factor) || 1;
    const selectscale6Factor = parseFloat(scale6Factor) || 1;
    const selectscale7Factor = parseFloat(scale7Factor) || 1;
    const selectscale8Factor = parseFloat(scale8Factor) || 1;

    const populationSum = selectedCountries.reduce((total, country) => total + country.value.populationSize, 0);

    // Calculate adjusted population
    let adjustedPopulation;
    if (populationSum === 0) {
      adjustedPopulation = totalPopulationSize * factorValue * catFactor * genderFactor * ageRangeFactor * selectsec *
        selectscale1Factor * selectscale2Factor * selectscale3Factor * selectscale4Factor *
        selectscale5Factor * selectscale6Factor * selectscale7Factor * selectscale8Factor;
    } else {
      adjustedPopulation = populationSum * factorValue * catFactor * genderFactor * ageRangeFactor * selectsec *
        selectscale1Factor * selectscale2Factor * selectscale3Factor * selectscale4Factor *
        selectscale5Factor * selectscale6Factor * selectscale7Factor * selectscale8Factor;
    }

    setSelectedPopulationSize(adjustedPopulation);
  };

  const handleScaleChange = (event) => {
    const value = event.target.value;
    setScale1Selected(value);
    const selected = scale1.find(item => item.text === value);
    if (selected) {
      const factorValue = parseFloat(selected.factor); // Extracting factor value from selectedSec
      setScale1Factor(factorValue);
      setScale1Value(selected.value);
      // Ensure that all factors used in the calculation are initialized and valid
      const catFactor = parseFloat(selectedCatFactor) || 1; // Use 1 as default if selectedCatFactor is not a valid number
      const subCatFactor = parseFloat(selectedSubCatFactor) || 1; // Use 1 as default if selectedSubCatFactor is not a valid number
      const genderFactor = parseFloat(selectedGenderFactor) || 1;
      const ageRangeFactor = parseFloat(selectedAgeRange) || 1;
      const scale1Factor = parseFloat(factorValue) || 1; // Use 1 as default if selectedScale1 is not a valid number
      const selectscale2Factor = parseFloat(scale2Factor) || 1; // Use 1 as default if selectedScale2 is not a valid number
      const selectscale3Factor = parseFloat(scale3Factor) || 1; // Use 1 as default if selectedScale3 is not a valid number
      const selectscale4Factor = parseFloat(scale4Factor) || 1; // Use 1 as default if selectedScale4 is not a valid number
      const selectscale5Factor = parseFloat(scale5Factor) || 1; // Use 1 as default if selectedScale5 is not a valid number
      const selectscale6Factor = parseFloat(scale6Factor) || 1; // Use 1 as default if selectedScale6 is not a valid number
      const selectscale7Factor = parseFloat(scale7Factor) || 1; // Use 1 as default if selectedScale7 is not a valid number
      const selectscale8Factor = parseFloat(scale8Factor) || 1; // Use 1 as default if selectedScale8 is not a valid number

      const populationSum = selectedCountries.reduce((total, country) => total + country.value.populationSize, 0);
      let adjustedPopulation;
      if (populationSum === 0) {
        adjustedPopulation = totalPopulationSize * scale1Factor * selectscale2Factor * selectscale3Factor
          * selectscale4Factor * selectscale5Factor * selectscale6Factor * selectscale7Factor
          * selectscale8Factor * catFactor * subCatFactor * genderFactor * ageRangeFactor;
      } else {
        adjustedPopulation = populationSum * scale1Factor * selectscale2Factor * selectscale3Factor
          * selectscale4Factor * selectscale5Factor * selectscale6Factor * selectscale7Factor
          * selectscale8Factor * catFactor * subCatFactor * genderFactor * ageRangeFactor;
      }

      setSelectedPopulationSize(adjustedPopulation);
    } else {
      setScale1Factor(''); // Reset the factor value if selected is not found
    }

  };
  const handleScale1Change = (event) => {
    const value = event.target.value;
    setScale2Selected(value);
    const selected = scale2.find(item => item.text === value);
    if (selected) {
      const factorValue = parseFloat(selected.factor); // Extracting factor value from selectedSec
      setScale2Factor(factorValue);
      setScale2Value(selected.value);
      // Ensure that all factors used in the calculation are initialized and valid
      const catFactor = parseFloat(selectedCatFactor) || 1; // Use 1 as default if selectedCatFactor is not a valid number
      const subCatFactor = parseFloat(selectedSubCatFactor) || 1; // Use 1 as default if selectedSubCatFactor is not a valid number
      const genderFactor = parseFloat(selectedGenderFactor) || 1;
      const ageRangeFactor = parseFloat(selectedAgeRange) || 1;
      const selectsec = parseFloat(secFactor) || 1;
      const selectscale1Factor = parseFloat(scale1Factor) || 1; // Use 1 as default if selectedScale1 is not a valid number
      const scale2Factor = parseFloat(factorValue) || 1; // Use 1 as default if selectedScale2 is not a valid number
      const selectscale3Factor = parseFloat(scale3Factor) || 1; // Use 1 as default if selectedScale3 is not a valid number
      const selectscale4Factor = parseFloat(scale4Factor) || 1; // Use 1 as default if selectedScale4 is not a valid number
      const selectscale5Factor = parseFloat(scale5Factor) || 1; // Use 1 as default if selectedScale5 is not a valid number
      const selectscale6Factor = parseFloat(scale6Factor) || 1; // Use 1 as default if selectedScale6 is not a valid number
      const selectscale7Factor = parseFloat(scale7Factor) || 1; // Use 1 as default if selectedScale7 is not a valid number
      const selectscale8Factor = parseFloat(scale8Factor) || 1; // Use 1 as default if selectedScale8 is not a valid number


      const populationSum = selectedCountries.reduce((total, country) => total + country.value.populationSize, 0);
      let adjustedPopulation;
      if (populationSum === 0) {
        adjustedPopulation = totalPopulationSize * scale2Factor * selectscale1Factor
          * selectscale3Factor * selectscale4Factor * selectscale5Factor * selectscale6Factor * selectscale7Factor
          * selectscale8Factor * catFactor * subCatFactor * genderFactor * ageRangeFactor * selectsec;
      } else {
        adjustedPopulation = populationSum * scale2Factor * selectscale1Factor
          * selectscale3Factor * selectscale4Factor * selectscale5Factor * selectscale6Factor * selectscale7Factor
          * selectscale8Factor * catFactor * subCatFactor * genderFactor * ageRangeFactor * selectsec;
      }

      setSelectedPopulationSize(adjustedPopulation);
    } else {
      setScale2Factor(''); // Reset the factor value if selected is not found
    }

  };
  const handleScale2Change = (event) => {
    const value = event.target.value;
    setScale3Selected(value);
    const selected = scale3.find(item => item.text === value);
    if (selected) {
      const factorValue = parseFloat(selected.factor); // Extracting factor value from selectedSec
      setScale3Factor(factorValue);
      setScale3Value(selected.value);
      // Ensure that all factors used in the calculation are initialized and valid
      const catFactor = parseFloat(selectedCatFactor) || 1; // Use 1 as default if selectedCatFactor is not a valid number
      const subCatFactor = parseFloat(selectedSubCatFactor) || 1; // Use 1 as default if selectedSubCatFactor is not a valid number
      const genderFactor = parseFloat(selectedGenderFactor) || 1;
      const ageRangeFactor = parseFloat(selectedAgeRange) || 1;
      const selectsec = parseFloat(secFactor) || 1;
      const selectscale1Factor = parseFloat(scale1Factor) || 1; // Use 1 as default if selectedScale1 is not a valid number
      const selectscale2Factor = parseFloat(scale2Factor) || 1; // Use 1 as default if selectedScale2 is not a valid number
      const scale3Factor = parseFloat(factorValue) || 1; // Use 1 as default if selectedScale3 is not a valid number
      const selectscale4Factor = parseFloat(scale4Factor) || 1; // Use 1 as default if selectedScale4 is not a valid number
      const selectscale5Factor = parseFloat(scale5Factor) || 1; // Use 1 as default if selectedScale5 is not a valid number
      const selectscale6Factor = parseFloat(scale6Factor) || 1; // Use 1 as default if selectedScale6 is not a valid number
      const selectscale7Factor = parseFloat(scale7Factor) || 1; // Use 1 as default if selectedScale7 is not a valid number
      const selectscale8Factor = parseFloat(scale8Factor) || 1; // Use 1 as default if selectedScale8 is not a valid number

      const populationSum = selectedCountries.reduce((total, country) => total + country.value.populationSize, 0);
      let adjustedPopulation;
      if (populationSum === 0) {
        adjustedPopulation = totalPopulationSize * scale3Factor * selectscale1Factor
          * selectscale2Factor * selectscale4Factor * selectscale5Factor * selectscale6Factor * selectscale7Factor
          * selectscale8Factor * catFactor * subCatFactor * genderFactor * ageRangeFactor * selectsec;
      } else {
        adjustedPopulation = populationSum * scale3Factor * selectscale1Factor
          * selectscale2Factor * selectscale4Factor * selectscale5Factor * selectscale6Factor * selectscale7Factor
          * selectscale8Factor * catFactor * subCatFactor * genderFactor * ageRangeFactor * selectsec;
      }

      setSelectedPopulationSize(adjustedPopulation);
    } else {
      setScale3Factor(''); // Reset the factor value if selected is not found
    }
  };
  const handleScale3Change = (event) => {
    const value = event.target.value;
    setScale4Selected(value);
    const selected = scale4.find(item => item.text === value);
    if (selected) {
      const factorValue = parseFloat(selected.factor); // Extracting factor value from selectedSec
      setScale4Factor(factorValue);
      setScale4Value(selected.value);
      // Ensure that all factors used in the calculation are initialized and valid
      const catFactor = parseFloat(selectedCatFactor) || 1; // Use 1 as default if selectedCatFactor is not a valid number
      const subCatFactor = parseFloat(selectedSubCatFactor) || 1; // Use 1 as default if selectedSubCatFactor is not a valid number
      const genderFactor = parseFloat(selectedGenderFactor) || 1;
      const ageRangeFactor = parseFloat(selectedAgeRange) || 1;
      const selectsec = parseFloat(secFactor) || 1;
      const selectscale1Factor = parseFloat(scale1Factor) || 1; // Use 1 as default if selectedScale1 is not a valid number
      const selectscale2Factor = parseFloat(scale2Factor) || 1; // Use 1 as default if selectedScale2 is not a valid number
      const selectscale3Factor = parseFloat(scale3Factor) || 1; // Use 1 as default if selectedScale3 is not a valid number
      const scale4Factor = parseFloat(factorValue) || 1; // Use 1 as default if selectedScale4 is not a valid number
      const selectscale5Factor = parseFloat(scale5Factor) || 1; // Use 1 as default if selectedScale5 is not a valid number
      const selectscale6Factor = parseFloat(scale6Factor) || 1; // Use 1 as default if selectedScale6 is not a valid number
      const selectscale7Factor = parseFloat(scale7Factor) || 1; // Use 1 as default if selectedScale7 is not a valid number
      const selectscale8Factor = parseFloat(scale8Factor) || 1; // Use 1 as default if selectedScale8 is not a valid number

      const populationSum = selectedCountries.reduce((total, country) => total + country.value.populationSize, 0);
      let adjustedPopulation;
      if (populationSum === 0) {
        adjustedPopulation = totalPopulationSize * scale4Factor * selectscale1Factor * selectscale2Factor * selectscale3Factor
          * selectscale5Factor * selectscale6Factor * selectscale7Factor *
          selectscale8Factor * catFactor * subCatFactor * genderFactor * ageRangeFactor * selectsec;
      } else {
        adjustedPopulation = populationSum * scale4Factor * selectscale1Factor * selectscale2Factor * selectscale3Factor
          * selectscale5Factor * selectscale6Factor * selectscale7Factor *
          selectscale8Factor * catFactor * subCatFactor * genderFactor * ageRangeFactor * selectsec;
      }

      setSelectedPopulationSize(adjustedPopulation);
    } else {
      setScale4Factor(''); // Reset the factor value if selected is not found
    }
  };
  const handleScale4Change = (event) => {
    const value = event.target.value;
    setScale5Selected(value);
    const selected = scale5.find(item => item.text === value);
    if (selected) {
      const factorValue = parseFloat(selected.factor); // Extracting factor value from selectedSec
      setScale5Factor(factorValue);
      setScale5Value(selected.value);
      // Ensure that all factors used in the calculation are initialized and valid
      const catFactor = parseFloat(selectedCatFactor) || 1; // Use 1 as default if selectedCatFactor is not a valid number
      const subCatFactor = parseFloat(selectedSubCatFactor) || 1; // Use 1 as default if selectedSubCatFactor is not a valid number
      const genderFactor = parseFloat(selectedGenderFactor) || 1;
      const ageRangeFactor = parseFloat(selectedAgeRange) || 1;
      const selectsec = parseFloat(secFactor) || 1;
      const selectscale1Factor = parseFloat(scale1Factor) || 1; // Use 1 as default if selectedScale1 is not a valid number
      const selectscale2Factor = parseFloat(scale2Factor) || 1; // Use 1 as default if selectedScale2 is not a valid number
      const selectscale3Factor = parseFloat(scale3Factor) || 1; // Use 1 as default if selectedScale3 is not a valid number
      const selectscale4Factor = parseFloat(scale4Factor) || 1; // Use 1 as default if selectedScale4 is not a valid number
      const scale5Factor = parseFloat(factorValue) || 1; // Use 1 as default if selectedScale5 is not a valid number
      const selectscale6Factor = parseFloat(scale6Factor) || 1; // Use 1 as default if selectedScale6 is not a valid number
      const selectscale7Factor = parseFloat(scale7Factor) || 1; // Use 1 as default if selectedScale7 is not a valid number
      const selectscale8Factor = parseFloat(scale8Factor) || 1; // Use 1 as default if selectedScale8 is not a valid number

      const populationSum = selectedCountries.reduce((total, country) => total + country.value.populationSize, 0);
      let adjustedPopulation;
      if (populationSum === 0) {
        adjustedPopulation = totalPopulationSize * scale5Factor * selectscale1Factor * selectscale2Factor *
          selectscale3Factor * selectscale4Factor * selectscale6Factor * selectscale7Factor * selectscale8Factor * catFactor *
          subCatFactor * genderFactor * ageRangeFactor * selectsec;
      } else {
        adjustedPopulation = populationSum * scale5Factor * selectscale1Factor * selectscale2Factor *
          selectscale3Factor * selectscale4Factor * selectscale6Factor * selectscale7Factor * selectscale8Factor * catFactor *
          subCatFactor * genderFactor * ageRangeFactor * selectsec;
      }

      setSelectedPopulationSize(adjustedPopulation);
    } else {
      setScale5Factor(''); // Reset the factor value if selected is not found
    }
  };
  const handleScale5Change = (event) => {
    const value = event.target.value;
    setScale6Selected(value);
    const selected = scale6.find(item => item.text === value);
    if (selected) {
      const factorValue = parseFloat(selected.factor); // Extracting factor value from selectedSec
      setScale6Factor(factorValue);
      setScale6Value(selected.value);
      // Ensure that all factors used in the calculation are initialized and valid
      const catFactor = parseFloat(selectedCatFactor) || 1; // Use 1 as default if selectedCatFactor is not a valid number
      const subCatFactor = parseFloat(selectedSubCatFactor) || 1; // Use 1 as default if selectedSubCatFactor is not a valid number
      const genderFactor = parseFloat(selectedGenderFactor) || 1;
      const ageRangeFactor = parseFloat(selectedAgeRange) || 1;
      const selectsec = parseFloat(secFactor) || 1;
      const selectscale1Factor = parseFloat(scale1Factor) || 1; // Use 1 as default if selectedScale1 is not a valid number
      const selectscale2Factor = parseFloat(scale2Factor) || 1; // Use 1 as default if selectedScale2 is not a valid number
      const selectscale3Factor = parseFloat(scale3Factor) || 1; // Use 1 as default if selectedScale3 is not a valid number
      const selectselectscale4Factor = parseFloat(scale4Factor) || 1; // Use 1 as default if selectedScale4 is not a valid number
      const selectscale5Factor = parseFloat(scale5Factor) || 1; // Use 1 as default if selectedScale5 is not a valid number
      const scale6Factor = parseFloat(factorValue) || 1; // Use 1 as default if selectedScale6 is not a valid number
      const selectscale7Factor = parseFloat(scale7Factor) || 1; // Use 1 as default if selectedScale7 is not a valid number
      const selectscale8Factor = parseFloat(scale8Factor) || 1; // Use 1 as default if selectedScale8 is not a valid number

      const populationSum = selectedCountries.reduce((total, country) => total + country.value.populationSize, 0);
      let adjustedPopulation;
      if (populationSum === 0) {
        adjustedPopulation = totalPopulationSize * scale6Factor * selectscale7Factor * selectscale8Factor *
          selectscale5Factor * selectselectscale4Factor * selectscale3Factor * selectscale2Factor
          * selectscale1Factor * catFactor * subCatFactor *
          genderFactor * ageRangeFactor * selectsec;
      } else {
        adjustedPopulation = populationSum * scale6Factor * selectscale7Factor * selectscale8Factor *
          selectscale5Factor * selectselectscale4Factor * selectscale3Factor * selectscale2Factor
          * selectscale1Factor * catFactor * subCatFactor *
          genderFactor * ageRangeFactor * selectsec;
      }

      setSelectedPopulationSize(adjustedPopulation);
    } else {
      setScale6Factor(''); // Reset the factor value if selected is not found
    }
  };
  const handleScale6Change = (event) => {
    const value = event.target.value;
    setScale7Selected(value);
    const selected = scale7.find(item => item.text === value);
    if (selected) {
      const factorValue = parseFloat(selected.factor); // Extracting factor value from selectedSec
      setScale7Factor(factorValue);
      setScale7Value(selected.value);
      // Ensure that all factors used in the calculation are initialized and valid
      const catFactor = parseFloat(selectedCatFactor) || 1; // Use 1 as default if selectedCatFactor is not a valid number
      const subCatFactor = parseFloat(selectedSubCatFactor) || 1; // Use 1 as default if selectedSubCatFactor is not a valid number
      const genderFactor = parseFloat(selectedGenderFactor) || 1;
      const ageRangeFactor = parseFloat(selectedAgeRange) || 1;
      const selectsec = parseFloat(secFactor) || 1;
      const selectscale1Factor = parseFloat(scale1Factor) || 1; // Use 1 as default if selectedScale1 is not a valid number
      const selectscale2Factor = parseFloat(scale2Factor) || 1; // Use 1 as default if selectedScale2 is not a valid number
      const selectscale3Factor = parseFloat(scale3Factor) || 1; // Use 1 as default if selectedScale3 is not a valid number
      const selectscale4Factor = parseFloat(scale4Factor) || 1; // Use 1 as default if selectedScale4 is not a valid number
      const selectscale5Factor = parseFloat(scale5Factor) || 1; // Use 1 as default if selectedScale5 is not a valid number
      const selectscale6Factor = parseFloat(scale6Factor) || 1; // Use 1 as default if selectedScale6 is not a valid number
      const scale7Factor = parseFloat(factorValue) || 1; // Use 1 as default if selectedScale7 is not a valid number
      const selectscale8Factor = parseFloat(scale8Factor) || 1; // Use 1 as default if selectedScale8 is not a valid number

      const populationSum = selectedCountries.reduce((total, country) => total + country.value.populationSize, 0);
      let adjustedPopulation;
      if (populationSum === 0) {
        adjustedPopulation = totalPopulationSize * scale7Factor * selectscale8Factor *
          selectscale6Factor * selectscale5Factor * selectscale4Factor * selectscale3Factor *
          selectscale2Factor * selectscale1Factor * catFactor *
          subCatFactor * genderFactor * ageRangeFactor * selectsec;
      } else {
        adjustedPopulation = populationSum * scale7Factor * selectscale8Factor *
          selectscale6Factor * selectscale5Factor * selectscale4Factor * selectscale3Factor *
          selectscale2Factor * selectscale1Factor * catFactor *
          subCatFactor * genderFactor * ageRangeFactor * selectsec;
      }

      setSelectedPopulationSize(adjustedPopulation);
    } else {
      setScale7Factor(''); // Reset the factor value if selected is not found
    }
  };
  const handleScale7Change = (event) => {
    const value = event.target.value;
    setScale8Selected(value);
    const selected = scale8.find(item => item.text === value);
    if (selected) {
      const factorValue = parseFloat(selected.factor); // Extracting factor value from selectedSec
      setScale8Factor(factorValue);
      setScale8Value(selected.value);
      // Ensure that all factors used in the calculation are initialized and valid
      const catFactor = parseFloat(selectedCatFactor) || 1; // Use 1 as default if selectedCatFactor is not a valid number
      const subCatFactor = parseFloat(selectedSubCatFactor) || 1; // Use 1 as default if selectedSubCatFactor is not a valid number
      const genderFactor = parseFloat(selectedGenderFactor) || 1;
      const ageRangeFactor = parseFloat(selectedAgeRange) || 1;
      const selectsec = parseFloat(secFactor) || 1;
      const selectscale1Factor = parseFloat(scale1Factor) || 1; // Use 1 as default if selectedScale1 is not a valid number
      const selectscale2Factor = parseFloat(scale2Factor) || 1; // Use 1 as default if selectedScale2 is not a valid number
      const selectscale3Factor = parseFloat(scale3Factor) || 1; // Use 1 as default if selectedScale3 is not a valid number
      const selectscale4Factor = parseFloat(scale4Factor) || 1; // Use 1 as default if selectedScale4 is not a valid number
      const selectscale5Factor = parseFloat(scale5Factor) || 1; // Use 1 as default if selectedScale5 is not a valid number
      const selectscale6Factor = parseFloat(scale6Factor) || 1; // Use 1 as default if selectedScale6 is not a valid number
      const selectscale7Factor = parseFloat(scale7Factor) || 1; // Use 1 as default if selectedScale7 is not a valid number
      const scale8Factor = parseFloat(factorValue) || 1; // Use 1 as default if selectedScale8 is not a valid number

      const populationSum = selectedCountries.reduce((total, country) => total + country.value.populationSize, 0);
      let adjustedPopulation;
      if (populationSum === 0) {
        adjustedPopulation = totalPopulationSize * scale8Factor * selectscale7Factor *
          selectscale6Factor * selectscale5Factor * selectscale4Factor *
          selectscale3Factor * selectscale2Factor * selectscale1Factor
          * catFactor * subCatFactor * genderFactor * ageRangeFactor * selectsec;
      } else {
        adjustedPopulation = populationSum * scale8Factor * selectscale7Factor *
          selectscale6Factor * selectscale5Factor * selectscale4Factor *
          selectscale3Factor * selectscale2Factor * selectscale1Factor
          * catFactor * subCatFactor * genderFactor * ageRangeFactor * selectsec;
      }

      setSelectedPopulationSize(adjustedPopulation);
    }
    else {
      setScale8Factor(''); // Reset the factor value if selected is not found
    }
  };

  const FilterSelect = ({ label, options, value, onChange }) => (
    <>
      <label>{label}:</label>
      <br />
      <select className='select' value={value} onChange={onChange}>
        <option value="">-- Please select --</option>
        {options.map(option => (
          <option key={option.id} value={option.text}>{option.text}</option>
        ))}
      </select>
      <br />
    </>
  );

  return (
    <div className="calculator" style={{ fontFamily: 'Avenir Pro' }}>
      <div className="filters" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <div>
          <div className="filters-container">
            <DownloadPPT
              showScenarios={showScenarios}
              baseScenarioValues={baseScenarioValues}
              scenario1Values={scenario1Values}
              scenario2Values={scenario2Values}
              scenario3Values={scenario3Values}
              estimatedVolumeBase={estimatedVolumeBase}
              estimatedValueBase={estimatedValueBase}
              estimatedVolumeScenario1={estimatedVolumeScenario1}
              estimatedValueScenario1={estimatedValueScenario1}
              estimatedVolumeScenario2={estimatedVolumeScenario2}
              estimatedValueScenario2={estimatedValueScenario2}
              estimatedVolumeScenario3={estimatedVolumeScenario3}
              estimatedValueScenario3={estimatedValueScenario3}
              appliedFilters={{
                Country: selectedCountries.map((country) => country.label).join(", "),
                Gender: selectedGenders.map((gender) => gender.label).join(", "),
                Age: selectedRange.map((age) => age.label).join(", "),
                Category: selectedCatValue || "N/A",
                SubCategory: subCategories.map((sub) => sub.label).join(", "),
              }}
            />

            <button onClick={clearFilters} className="reset-filters-button">
              <FaSyncAlt className="refresh-icon" />
              Reset Filters
            </button>
          </div>
          {/* <div className="filter-item"> */}
          <FaFilter className="filter-icon" />
          <label>Type of Product:</label>
          <select className="select" value={selectedFilter} onChange={handleAdditionalFilterChange}>
            <option value="">--Please Select--</option>
            {filterOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
          {/* </div> */}

          {/* Conditionally render additional input fields based on the selected filter */}
          {selectedFilter === 'NPD' && (
            <div className="filter-item">
              <FaCalendarAlt className="filter-icon" />
              <label>Year of launch:</label>
              <select className="select" value={yearValue} onChange={handleAdditionalInfoChange}>
                {yearOptions.map((year, index) => (
                  <option key={index} value={year} disabled={disabledYears.includes(year)}>
                    {year}
                  </option>
                ))}
              </select>
              <br />
              <div>
                <FaFileAlt className="filter-icon" />
                <label>Launch Name:</label>
                <input type="text" value={launchName} onChange={handleLaunchNameChange} placeholder="Enter launch name" />
              </div>
              <br />
              <div>
                <FaFileAlt className="filter-icon" />
                <label>Product Picture:</label>
                <input type="file" accept=".jpg,.jpeg,.png,.pdf" onChange={handleProductPictureChange} />
              </div>
            </div>
          )}

          {selectedFilter === 'Existing Product' && (
            <div className="filter-item">
              <FaCogs className="filter-icon" />
              <label>Sales of previous year Volume:</label>
              <input type="number" value={additionalVolumeInfo} onChange={handleAdditionalVolumeInfoChange} />
              <br />
              <label>Sales of previous year Value:</label>
              <input type="number" value={additionalValueInfo} onChange={handleAdditionalValueInfoChange} />
            </div>
          )}

          <div className="filter-item">
            <FaMapMarkerAlt className="filter-icon" />
            <h3>Choose your target audience</h3>
            <FaGlobe className="filter-icon" />
            <label>Select Country:</label>
            <Select
              className="select"
              value={selectedCountries}
              onChange={handleCountryChange}
              options={countries.map(country => ({ value: country, label: country.text }))}
              isMulti
            />
          </div><br></br>

          <div className="filter-item">
            <FaUserCircle className="filter-icon" />
            <label>Select Gender:</label>
            <Select
              className="select"
              value={selectedGenders}
              onChange={handleGenderChange}
              options={genders.map(gender => ({ value: gender.value, label: gender.text }))}
              isMulti
            />
          </div>

          <div className="filter-item">
            <h3>Category details</h3>
            <FaBoxOpen className="filter-icon" />
            <label>Select a Category:</label>
            <select className="select" value={selectedCatValue} onChange={handleSlectedCatChange}>
              <option value="">--Please select--</option>
              {selectedCat.map(selectedCat => (
                <option key={selectedCat.id} value={selectedCat.text}>{selectedCat.text}</option>
              ))}
            </select>
            <FaListAlt className="filter-icon" />
            <label>Select a Sub-Category:</label>
            <select className="select" value={selectedSubCatValue} onChange={handleSlectedSubCatChange}>
              <option value="">--Please select--</option>
              {subCategories.map(selectedSubCat => (
                <option key={selectedSubCat.id} value={selectedSubCat.text}>{selectedSubCat.text}</option>
              ))}
            </select>
          </div><br></br>

          <div className="filter-item">
          <FaUsers className="filter-icon" />
            <label>Select Age Range:</label>
            <Select
              className="select"
              value={selectedRange}
              onChange={handleAgeSelectChange}
              options={ageRanges.map(ageRange => ({ value: ageRange.value, label: ageRange.label }))}
              isMulti
            />
          </div><br></br>

          <div className="filter-item">
            <FaCoins className="filter-icon" />
            <label>Social Economic Class:</label>
            <Select
              className="select"
              value={secValue}
              onChange={handleSecChange}
              options={[
                { value: "A/B", label: "Affluent class (SEC A/B)" },
                { value: "C1/C2", label: "Mass Segment (C1/C2)" },
                { value: "D/E", label: "Less Affluent (D/E)" }
              ]}
              isMulti
            />
          </div>

          <div className="filters1">
            <h3>Additional Support</h3>
            <FilterSelect label="Sub-Category Experience in Country" options={scale1} value={scale1Selected} onChange={handleScaleChange} />
            <FilterSelect label="Media Investment" options={scale2} value={scale2Selected} onChange={handleScale1Change} />
            <FilterSelect label="Category Growth" options={scale3} value={scale3Selected} onChange={handleScale2Change} />
            <FilterSelect label="Offline Price Promotions" options={scale6} value={scale6Selected} onChange={handleScale5Change} />
          </div>
        </div>
      </div>
      <div className='main'>
        {/* <button>Input</button> */}
        <div style={{ border: '2px solid #0032a0', backgroundColor: '#0032a0', color: 'white', textAlign: 'center', padding: '10px' }}></div>
        {(showScenarios < 3 &&
          <button className='addbtn' onClick={handleAddScenario} style={{ marginTop: '1%', marginLeft: '3%' }}>
            + Add Scenario
          </button>
        )}
        <div className="form">
          <div className="container">
            <div className="scenario-container" style={{ display: "flex"}}>
              {Array.from({ length: showScenarios + 1 }).map((_, index) => (
                <div key={index} style={{ marginLeft: '1%' }}>
                  <div className="card">
                    <ScenarioForm
                      title={index === 0 ? "Base Scenario" : `Scenario ${index}`}
                      values={index === 0 ? baseScenarioValues : index === 1 ? scenario1Values : index === 2 ? scenario2Values : scenario3Values}
                      handleValuesChange={index === 0 ? handleBaseScenarioValues : index === 1 ? handleScenario1Values : index === 2 ? handleScenario2Values : handleScenario3Values}
                      selectedPopulationSize={selectedPopulationSize}
                      estimatedVolume={index === 0 ? estimatedVolumeBase : index === 1 ? estimatedVolumeScenario1 : index === 2 ? estimatedVolumeScenario2 : estimatedVolumeScenario3}
                      estimatedValue={index === 0 ? estimatedValueBase : index === 1 ? estimatedValueScenario1 : index === 2 ? estimatedValueScenario2 : estimatedValueScenario3}
                    />
                    {index > 0 && (
                      <button onClick={() => handleRemoveScenario(index)}>
                        - Remove Scenario {index}
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

function App() {
  return (
    <div style={{ fontFamily: 'Avenir Pro', backgroundColor: '#f5f5f5' }}>
      <div style={{ display: "flex"}}>
        <img src={favicon} alt="Logo" />
        <ImageSlider images={images} style={{ width: "50px", height: "50px" }}/>
      </div>
      {/* <h1>CRYSTAL BALL</h1> */}
      <Calculator />
    </div>
  );
}

export default App;
