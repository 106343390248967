import React from "react";

const ImageReel = ({ images }) => {
  const repeatedImages = [...images, ...images]; // Duplicate the images for seamless scrolling

  return (
    <div style={{ overflow: "hidden", whiteSpace: "nowrap", backgroundColor: "#fff", padding: "10px", borderRadius: "8px" }}>
      <div
        style={{
          display: "inline-block",
          animation: "reel-scroll 20s linear infinite",
        }}
      >
        {repeatedImages.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={image.alt}
            style={{
              width: "100px",
              height: "10vh",
              margin: "0 10px",
              display: "inline-block",
              borderRadius: "8px",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageReel;
